/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import {
  verifySessionToken,
  loadAccountData,
  saveAccountData,
} from "common/account.js";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import moment from "moment";
import Moment from "react-moment";
import Footer from "controllers/components/Footer";
import LayoutHeader from "controllers/components/Header";
import Loader from "controllers/components/Loader";
import { getRandomItem, findCommonElements } from "common/array";
import { validEmail } from "common/validator.js";
import "moment-timezone";
import logo from "assets/images/logo.png";
import logoWhite from "assets/images/logo-white.png";
const axios = require("axios");
const slugify = require("slugify");

export default class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: loadAccountData(),
      session_verified: false,
      password: "",
      email: "",
      source: "thithu.vn",
      referral: 0,
      show_login_button: true,
    };
    moment.locale("vi");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.verifySession();
  }

  verifySession = () => {
    let $this = this;
    let session = loadAccountData();
    if (session.token) {
      verifySessionToken(session).then(function (result) {
        log(result);
        $this.setState({ session: result, session_verified: true }, () => {
          window.location.href = "/#/";
        });
      });
    }
  };

  handleFormSubmit = () => {
    let $this = this;
    let hasAlerted = false;
    let ok = true;
    let formData = {
      password: $this.state.password,
      email: $this.state.email,
    };

    // Check mail
    if (formData.password === "") {
      ok = false;
    }
    // Check mail
    if (formData.email === "") {
      ok = false;
    } else {
      if (validEmail(formData.email) === false) {
        ok = false;
      }
    }

    if (ok === true) {
      $this.setState({ show_login_button: false }, () => {
        axios
          .post(config.api.account_login, formData)
          .then(function (response) {
            log(response.data);
            saveAccountData(response.data);
            $this.verifySession();
          })
          .catch(function (error) {
            log(error);
            alert(error.response.data.detail);
            $this.setState({ show_login_button: true }, () => {});
          })
          .then(function () {});
      });
    } else {
      if (hasAlerted === false) {
        alert("Mời bạn nhập đầy đủ thông tin trên form.");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <LayoutHeader
          session={this.state.session}
          sessionVerified={this.state.session_verified}
        />
        <div
          className="w-full pt-12 bg-gray-200 pb-12"
          style={{ paddingBottom: 200, paddingTop: 120 }}
        >
          {!this.state.session.token ? (
            !this.state.session_verified ? (
              <div className="container w-6/12 mx-auto gap-8">
                <div className="w-full pt-4 pb-4 pr-4 pl-4 bg-gray-200">
                  <div>
                    <form className=" mx-auto bg-white shadow rounded">
                      <div className="xl:w-full border-b border-gray-300 py-6">
                        <div className="flex items-center w-11/12 mx-auto">
                          <div className="text-lg text-gray-800 font-bold">
                            Đăng nhập tài khoản
                          </div>
                          <div className="ml-2 cursor-pointer text-gray-600">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={16}
                              height={16}
                            >
                              <path
                                className="heroicon-ui"
                                d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="w-11/12 mx-auto">
                        <div className="container mx-auto">
                          <div
                            className="my-6 mx-auto xl:w-full xl:mx-0"
                            style={{ marginTop: 20, marginBottom: 30 }}
                          >
                            <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                              <label
                                htmlFor="email2"
                                className="pb-2 text-sm font-bold text-gray-800"
                              >
                                Email
                              </label>
                              <div className="relative">
                                <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-mail"
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <rect
                                      x={3}
                                      y={5}
                                      width={18}
                                      height={14}
                                      rx={2}
                                    />
                                    <polyline points="3 7 12 13 21 7" />
                                  </svg>
                                </div>
                                <input
                                  value={this.state.email}
                                  onChange={(e) => {
                                    this.setState({ email: e.target.value });
                                  }}
                                  id="email2"
                                  name="email"
                                  required
                                  className="w-full text-gray-800 focus:outline-none  focus:border focus:border-indigo-700 font-normal py-2 flex items-center pl-16 text-sm rounded border"
                                  placeholder="example@gmail.com"
                                />
                              </div>
                            </div>

                            <div className="xl:w-full lg:w-full md:w-full flex flex-col mb-4">
                              <label
                                htmlFor="FirstName"
                                className="pb-2 text-sm font-bold text-gray-800"
                              >
                                Mật khẩu
                              </label>
                              <input
                                type="password"
                                name="firstName"
                                required
                                id="FirstName"
                                className="border border-gray-300 pl-3 py-2 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800"
                                placeholder=""
                                value={this.state.password}
                                onChange={(e) => {
                                  this.setState({ password: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full py-3 sm:px-12 px-4 bg-gray-100  flex justify-end rounded-bl rounded-br">
                        {this.state.show_login_button ? (
                          <button
                            className="w-full bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-12 py-2 text-lg focus:outline-none"
                            type="button"
                            onClick={this.handleFormSubmit}
                          >
                            <strong>Đăng nhập</strong>
                          </button>
                        ) : (
                          <div className="w-full text-center justify-items-center text-gray-500 px-12 py-2">
                            <strong>Xin vui lòng đợi ...</strong>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
