import XXH from "xxhashjs";
import { Base64 } from "js-base64";
import randomstring from "randomstring";
import config from "config.js";
import log from "common/log.js";
const axios = require("axios");
let xxsalt = 0xabcd;
let keyPrefix = "UB|";
let sharedSalt = randomstring.generate(24);
let sharedUserDataKey = "account_data";
let sharedUserDataHashKey = "account_data_hash";
let userDataXXH = XXH.h32(keyPrefix + sharedUserDataKey, xxsalt).toString(16);
let userDataHashXXH = XXH.h32(
  keyPrefix + sharedUserDataHashKey,
  xxsalt
).toString(16);

function fromB64(input) {
  return Base64.decode(input.substr(36)).substr(12);
}

function toB64(input) {
  return (
    randomstring.generate(36) + Base64.encode(randomstring.generate(12) + input)
  );
}

function lset(k, v) {
  localStorage.setItem(k, v);
}

function lget(k) {
  return localStorage.getItem(k);
}

function lrm(k) {
  localStorage.removeItem(k);
}

export function verifySessionToken(session) {
  return axios
    .get(config.api.session_verify, {
      params: {
        token: session.token,
      },
    })
    .then(function (response) {
      // handle success
      log(response);
      let rsession = response.data;
      if (rsession.token) {
        if (
          rsession.token === session.token &&
          rsession.user.id === session.user.id
        ) {
          saveAccountData(rsession);
          return Promise.resolve(rsession);
        }
      }
      clearData();
    })
    .catch(function (error) {
      // handle error
      log(error);
      clearData();
    })
    .then(function (result) {
      // always executed
      return Promise.resolve(result);
    });
}

export function clearData() {
  localStorage.clear();
  window.location.reload();
}

export function loadAccountData() {
  // Load account data from local storage
  let k1str = lget(userDataXXH);
  let k2str = lget(userDataHashXXH);

  if (k1str != null && k2str != null) {
    let dataString = fromB64(k1str);
    let hashStr = k2str.substr(48);

    if (hashStr === XXH.h32(dataString, xxsalt).toString(16)) {
      log(dataString);
      return JSON.parse(dataString);
    }
  }

  return {};
}

export function saveAccountData(data) {
  // Save account data to local storage
  let dataString = JSON.stringify(data);
  let k1 = userDataXXH;
  let k2 = userDataHashXXH;
  let h = randomstring.generate(48) + XXH.h32(dataString, xxsalt).toString(16);
  lset(k1, toB64(dataString));
  lset(k2, h);
  lset("shared_data", randomstring.generate(248));
  lset("shared_salt", sharedSalt);
}
