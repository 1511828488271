/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { verifySessionToken, loadAccountData } from "common/account.js";
import moment from "moment";
import Moment from "react-moment";
import Footer from "controllers/components/Footer";
import LayoutHeader from "controllers/components/Header";
import Loader from "controllers/components/Loader";
import { getRandomItem, findCommonElements } from "common/array";
import "moment-timezone";
import logo from "assets/images/logo.png";
import logoWhite from "assets/images/logo-white.png";
const axios = require("axios");
const slugify = require("slugify");

let covers = [
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_9.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_10.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_11.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_12.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_13.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_14.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_15.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_16.png",
];

export default class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: loadAccountData(),
      session_verified: false,
      page: "listing",
      quiz: [],
      current_quiz: {},
      form_questions: {},
    };
    moment.locale("vi");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadListQuiz(this.props);
    this.verifySession();
  }

  verifySession = () => {
    let $this = this;
    let session = loadAccountData();
    if (session.token) {
      verifySessionToken(session).then(function (result) {
        log(result);
        $this.setState({ session: result, session_verified: true });
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        page: "listing",
        quiz: [],
        current_quiz: {},
        form_questions: {},
      },
      () => {
        this.loadListQuiz(nextProps);
      }
    );
  }
  componentDidUpdate() {}
  loadListQuiz = (props) => {
    let $this = this;

    axios
      .get(config.api.get_list_quiz, {
        params: { limit: 500, category: props.match.params.id },
      })
      .then(function (response) {
        $this.setState({ quiz: response.data.data }, () => {
          log($this.state);
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  };

  showListQuiz = () => {
    let table = [];
    if (this.state.quiz.length > 0) {
      // Outer loop to create parent
      for (let i = 0; i < this.state.quiz.length; i++) {
        let quiz = this.state.quiz[i];
        let quiz_id = quiz.id.toString();
        let quiz_slug = slugify(quiz.title, {
          locale: "vi",
          lower: true,
          strict: true,
        });
        table.push(
          <Fragment key={`quiz-${quiz_id}`}>
            <a href={`/#/quiz/${quiz_id}/${quiz_slug}`}>
              <div className="bg-white shadow border border-gray-200 rounded transition duration-150 ease-in-out hover:shadow-lg cursor-pointer">
                <div className="relative w-full h-40">
                  <img
                    className="rounded-t z-0 w-full h-full absolute inset-0 object-cover"
                    src={
                      quiz.cover_image == null
                        ? getRandomItem(covers)
                        : quiz.cover_image.data.full_url
                    }
                    alt="banner"
                  />
                </div>
                <div className="pt-5 w-full h-full">
                  <div className="px-5 items-center justify-between mb-4">
                    <p className="text-indigo-400 leading-3 tracking-normal text-sm font-normal">
                      {quiz.category === null
                        ? "Đề trắc nghiệm"
                        : quiz.category.title}
                    </p>
                  </div>
                  <h4 className="px-5 text-lg leading-5 font-bold tracking-normal text-gray-800 mb-2">
                    {quiz.title}
                  </h4>

                  {quiz.time_limit === null ? (
                    <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4 pb-4">
                      {" "}
                    </p>
                  ) : (
                    <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4">
                      Giới hạn thời gian: {quiz.time_limit} phút
                    </p>
                  )}

                  <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4">
                    <Moment
                      locale="vi"
                      date={quiz.created_on}
                      format={"DD/MM/yyyy"}
                    />
                  </p>
                </div>
              </div>
            </a>
          </Fragment>
        );
      }
    }

    return table;
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <LayoutHeader
            session={this.state.session}
            sessionVerified={this.state.session_verified}
          />
          <div
            className="w-full pt-12 bg-gray-200 pb-12"
            style={{ paddingBottom: 100, paddingTop: 120 }}
          >
            {this.state.quiz.length > 0 && this.state.page === "listing" ? (
              <div className="container w-11/12 mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-8">
                {this.showListQuiz()}
              </div>
            ) : (
              <div
                className="container w-11/12 mx-auto py-12"
                style={{ height: 800 }}
              >
                <Loader style={{ margin: "auto" }} />
              </div>
            )}
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
