/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import { Layout, Menu, Breadcrumb } from "antd";

import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import {
  verifySessionToken,
  loadAccountData,
  clearData,
} from "common/account.js";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
import logo from "assets/images/logo-thithu.png";
import logoWhite from "assets/images/logo-white.png";

const axios = require("axios");
const { SubMenu } = Menu;
const { Header } = Layout;
export default class LayoutHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: "shadow-sm",
      show_reg_links: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let activeClass = "shadow-md";
      if (window.scrollY === 0) {
        activeClass = "shadow-sm";
      }
      this.setState({ activeClass });
    });
  }

  render() {
    return (
      <Fragment>
        <Header
          className={`${this.state.activeClass} transition duration-150 ease-in-out desktop-header`}
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
            backgroundColor: "#fff",
          }}
        >
          <div
            className="logo"
            style={{
              width: 150,
              paddingTop: 18,
              float: "left",
              marginRight: 50,
            }}
          >
            <img
              src={logo}
              alt={"Logo"}
              width={"170"}
              onClick={() => {
                window.location.href = "/#/";
              }}
            />
          </div>

          <div className="flex justify-between">
            <Menu theme="light" mode="horizontal">
              <Menu.Item key="1">
                <a href={"/#/danh-muc/2/tieng-anh"}>
                  <strong>Tiếng Anh</strong>
                </a>
              </Menu.Item>
              <SubMenu
                key="SubMenu"
                title={<strong>Tài Chính - Ngân Hàng</strong>}
              >
                <Menu.ItemGroup title="">
                  <Menu.Item key="setting:1">
                    <a href={"/#/danh-muc/1/tai-chinh-ngan-hang"}>
                      Tài Chính - Ngân Hàng
                    </a>
                  </Menu.Item>
                  <Menu.Item key="setting:2">
                    <a href={"/#/danh-muc/5/thue"}>Thuế</a>
                  </Menu.Item>
                  <Menu.Item key="setting:3">
                    <a href={"/#/danh-muc/7/ke-toan-kiem-toan"}>
                      Kế toán - Kiểm toán
                    </a>
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>

              <Menu.Item key="3">
                <a href={"/#/danh-muc/6/cong-chuc-vien-chuc"}>
                  <strong>Công chức - Viên chức</strong>
                </a>
              </Menu.Item>
            </Menu>

            <Menu theme="light" mode="horizontal">
              {this.props.session.token ? (
                <React.Fragment>
                  <SubMenu
                    key="SubMenu"
                    title={
                      <div className="text-indigo-500">
                        <strong>{this.props.session.user.name}</strong>
                      </div>
                    }
                  >
                    <Menu.ItemGroup title="">
                      <Menu.Item key="5">
                        <div
                          onClick={() => {
                            clearData();
                          }}
                          style={{ fontSize: 13 }}
                        >
                          Đăng xuất
                        </div>
                      </Menu.Item>
                    </Menu.ItemGroup>
                  </SubMenu>
                </React.Fragment>
              ) : this.props.sessionVerified === false ? (
                <React.Fragment>
                  <Menu.Item key="4">
                    <a href={"/#/dang-ky"}>Đăng ký</a>
                  </Menu.Item>

                  <Menu.Item key="5">
                    <a href={"/#/dang-nhap"}>Đăng nhập</a>
                  </Menu.Item>
                </React.Fragment>
              ) : (
                ""
              )}
            </Menu>
          </div>
        </Header>
      </Fragment>
    );
  }
}
