import "moment-timezone";

import { Icon, InlineIcon } from "@iconify/react";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  useParams,
} from "react-router-dom";
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactHtmlParser, {
  convertNodeToElement,
  htmlparser2,
  processNodes,
} from "react-html-parser";
import { css, jsx } from "@emotion/react";
import { findCommonElements, getRandomItem } from "common/array";
import {
  loadAccountData,
  saveAccountData,
  verifySessionToken,
} from "common/account.js";

import { Affix } from "antd";
import Countdown from "react-countdown";
import Footer from "controllers/components/Footer";
import LayoutHeader from "controllers/components/Header";
import Loader from "controllers/components/Loader";
import Moment from "react-moment";
import ReactAudioPlayer from "react-audio-player";
import _ from "lodash";
import checkboxCircleFill from "@iconify-icons/ri/checkbox-circle-fill";
import config from "config.js";
import log from "common/log.js";
import logo from "assets/images/logo.png";
import logoWhite from "assets/images/logo-white.png";
import moment from "moment";

const axios = require("axios");

const Completionist = () => (
  <span>
    <strong>Hết thời gian</strong>
  </span>
);

function shortDescription(text) {
  return text.split(/\r\n|\r|\n/).map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
}

// Renderer callback with condition
const timeRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span className={"text-indigo-500"}>
        Thời gian:{" "}
        <strong>
          {hours}:{minutes}:{seconds}
        </strong>
      </span>
    );
  }
};

function showExplanation(e) {
  return (
    <React.Fragment>
      <br />
      {e.explanation_richtext ? (
        <React.Fragment>
          <div
            css={{ display: "flex", flexDirection: "row" }}
            className={"ml-6"}
          >
            {e.is_correct ? (
              <div css={{ width: 24, height: 24 }}>
                <Icon
                  icon={checkboxCircleFill}
                  color={"#32c259"}
                  width="22px"
                  height="22px"
                />
              </div>
            ) : (
              ""
            )}

            <div
              className="ml-3 text-base leading-4 font-normal item-explanation text-indigo-800"
              css={{ fontStyle: "italic" }}
            >
              {ReactHtmlParser(e.explanation_richtext)}

              {e.image ? (
                <React.Fragment>
                  <br /> <br />
                  <img src={e.image.data.full_url} />
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {e.explanation ? (
            <div
              css={{ display: "flex", flexDirection: "row" }}
              className={"ml-6"}
            >
              {e.is_correct ? (
                <div css={{ width: 24, height: 24 }}>
                  <Icon
                    icon={checkboxCircleFill}
                    color={"#32c259"}
                    width="22px"
                    height="22px"
                  />
                </div>
              ) : (
                ""
              )}

              <p
                className="ml-3 text-base leading-4 font-normal text-indigo-800"
                ss={{ fontStyle: "italic" }}
              >
                {shortDescription(e.explanation)}

                {e.image ? (
                  <React.Fragment>
                    <br /> <br />
                    <img src={e.image.data.full_url} />
                  </React.Fragment>
                ) : (
                  ""
                )}
              </p>
            </div>
          ) : (
            ""
          )}
        </React.Fragment>
      )}
      <br />
    </React.Fragment>
  );
}

export default class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_now: Date.now(),
      session: loadAccountData(),
      session_verified: false,
      page: "",
      quiz: [],
      current_quiz: {},
      form_questions: {},
      show_explanation: false,
    };
    moment.locale("vi");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <LayoutHeader
            session={this.state.session}
            sessionVerified={this.state.session_verified}
          />

          <div style={{ paddingTop: 120 }} className="bg-gray-200">
            <div className="container mx-auto px-6 ">
              <button
                className="my-2 bg-white transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-8 py-2 text-sm"
                onClick={() => {
                  const r = window.confirm("Bạn có muốn thoát ra ?");
                  if (r === true) {
                    window.location.href = "/#/";
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-arrow-back-up"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#9e9e9e"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ float: "left", marginRight: 10 }}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1" />
                </svg>
                Quay lại.
              </button>
            </div>
            <QuizHeadless quizId={this.props.match.params.id} affixTop={70} />
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

class QuizHeadless extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_now: Date.now(),
      session: loadAccountData(),
      session_verified: false,
      page: "",
      quiz: [],
      current_quiz: {},
      form_questions: {},
      show_explanation: false,
    };
    moment.locale("vi");
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let $this = this;
    axios
      .get(config.api.get_list_quiz + "/" + $this.props.quizId, {})
      .then(function (response) {
        log(response.data);
        $this.setState(
          {
            page: "quiz",
            current_quiz: response.data,
          },
          () => {
            window.scrollTo(0, 0);
            log($this.state);
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  }

  computeResult = () => {
    // log(this.state.current_quiz)
    let checkQuestionLength =
      Object.keys(this.state.form_questions).length !==
      this.state.current_quiz.questions.length;

    // let checkQuestionLength = false;

    if (checkQuestionLength) {
      alert("Bạn phải trả lời đủ các câu hỏi!");
    } else {
      let quiz = this.state.current_quiz;
      let form_questions = this.state.form_questions;
      let correct_questions = 0;

      for (let i = 0; i < quiz.questions.length; i++) {
        let question = quiz.questions[i];

        let form_question = form_questions["question_" + question.id];

        log("Question:", question);
        log("form_question:", form_question);
        log("question.correct_answers:", question.correct_answers);
        let correct_answers = 0;
        for (let a = 0; a < form_question.length; a++) {
          log("form_question[a]", form_question[a]);

          if (question.answer_type === "radio") {
            log(
              "question.correct_answers.includes",
              question.correct_answers.includes(form_question[a])
            );
            if (question.correct_answers.includes(form_question[a])) {
              correct_questions = correct_questions + 1;
            }
          } else {
            log(
              "question.correct_answers.includes",
              question.correct_answers.includes(form_question[a])
            );
            if (question.correct_answers.includes(form_question[a])) {
              correct_answers = correct_answers + 1;
            }

            log("correct_answers", correct_answers);
            log(
              "question.correct_answers.length",
              question.correct_answers.length
            );
            log(
              "correct_answers === question.correct_answers.length",
              correct_answers === question.correct_answers.length
            );
            if (correct_answers === question.correct_answers.length) {
              correct_questions = correct_questions + 1;
            }
          }
        }
      }

      alert(
        "Bạn đã trả lời đúng " +
          correct_questions.toString() +
          "/" +
          quiz.questions.length.toString() +
          " câu hỏi."
      );

      if (quiz.data.is_private) {
        this.setState({
          show_explanation: true,
        });
      }
    }
  };

  handleFormAnswer = (question, answer) => {
    let form_questions = { ...this.state.form_questions };

    let qk = "question_" + question.id;
    if (!form_questions.hasOwnProperty(qk)) {
      form_questions[qk] = [];
    }
    if (question.answer_type === "select") {
      if (form_questions[qk].includes(answer.id)) {
        form_questions[qk].removeIfExists(answer.id);
      } else {
        form_questions[qk].push(answer.id);
      }
    } else {
      form_questions[qk] = [answer.id];
    }

    log(Object.keys(form_questions).length);

    this.setState({ form_questions }, () => {
      log(this.state);
    });
  };

  showQuestionAnswers = (question) => {
    let $this = this;
    let qk = "question_" + question.id;

    return (
      <Fragment key={question.id}>
        <div className="flex flex-col items-start">
          {question.answers.map((e) => {
            let isGreenTitle =
              $this.state.show_explanation &&
              // !e.explanation_richtext &&
              // !e.explanation &&
              e.is_correct;

            let title = isGreenTitle ? (
              <div className="text-green-600 font-bold">{e.title}</div>
            ) : (
              <React.Fragment>{e.title}</React.Fragment>
            );

            if (
              $this.state.form_questions.hasOwnProperty(qk) &&
              $this.state.form_questions[qk].includes(e.id)
            ) {
              return (
                <Fragment key={e.id}>
                  <style>
                    {`  .checkbox:checked + .check-icon {
                              display: flex;
                          }`}
                  </style>
                  <div className="py-4 flex items-center ml-6">
                    {question.answer_type === "select" ? (
                      <React.Fragment>
                        <div
                          className="bg-white border rounded-sm border-gray-400
                      w-5 h-5 flex flex-shrink-0 justify-center items-center relative"
                        >
                          <input
                            onChange={() => {
                              this.handleFormAnswer(question, e);
                            }}
                            checked
                            type="checkbox"
                            className="checkbox opacity-0 absolute cursor-pointer w-full h-full"
                          />
                          <div className="check-icon hidden bg-indigo-700 text-white rounded-sm">
                            <svg
                              className="icon icon-tabler icon-tabler-check"
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              height={20}
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M5 12l5 5l10 -10" />
                            </svg>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div
                          className="bg-white rounded-full w-5 h-5 flex flex-shrink-0
                       justify-center items-center relative"
                        >
                          <input
                            onChange={() => {
                              this.handleFormAnswer(question, e);
                            }}
                            checked
                            type="radio"
                            name={"question-" + question.id}
                            className="checkbox appearance-none focus:outline-none border
                          rounded-full border-gray-400 absolute
                           cursor-pointer w-full h-full checked:border-none"
                            value={"answer-" + e.id}
                          />
                          <div
                            className="check-icon hidden border-4 border-indigo-700
                         rounded-full w-full h-full z-1"
                          />
                        </div>
                      </React.Fragment>
                    )}

                    <div
                      className="ml-3 text-base leading-4 font-normal text-gray-800"
                      css={{ display: "flex", flexDirection: "row" }}
                    >
                      {title}
                    </div>
                  </div>{" "}
                  {this.state.show_explanation ? showExplanation(e) : ""}
                </Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <style>
                    {`  .checkbox:checked + .check-icon {
                              display: flex;
                          }`}
                  </style>
                  <div className="py-4 flex items-center ml-6" key={e.id}>
                    {question.answer_type === "select" ? (
                      <React.Fragment>
                        <div
                          className="bg-white border rounded-sm border-gray-400
                    w-5 h-5 flex flex-shrink-0 justify-center items-center relative"
                        >
                          <input
                            onChange={() => {
                              this.handleFormAnswer(question, e);
                            }}
                            type="checkbox"
                            className="checkbox opacity-0 absolute cursor-pointer w-full h-full"
                          />
                          <div className="check-icon hidden bg-indigo-700 text-white rounded-sm">
                            <svg
                              className="icon icon-tabler icon-tabler-check"
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              height={20}
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <path d="M5 12l5 5l10 -10" />
                            </svg>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div
                          className="bg-white rounded-full w-5 h-5 flex flex-shrink-0
                     justify-center items-center relative"
                        >
                          <input
                            onChange={() => {
                              this.handleFormAnswer(question, e);
                            }}
                            type="radio"
                            name={"question-" + question.id}
                            value={"answer-" + e.id}
                            className="checkbox appearance-none focus:outline-none border
                        rounded-full border-gray-400 absolute cursor-pointer w-full h-full checked:border-none"
                          />
                          <div
                            className="check-icon hidden border-4 border-indigo-700
                       rounded-full w-full h-full z-1"
                          />
                        </div>
                      </React.Fragment>
                    )}

                    <div
                      className="ml-3 text-base leading-4 font-normal text-gray-800"
                      css={{ display: "flex", flexDirection: "row" }}
                    >
                      {title}
                    </div>
                  </div>{" "}
                  {this.state.show_explanation ? showExplanation(e) : ""}
                </React.Fragment>
              );
            }
          })}
        </div>
      </Fragment>
    );


  };

  showQuizQuestions = (questions) => {
    let table = [];
    if (questions.length > 0) {
      // Outer loop to create parent
      for (let i = 0; i < questions.length; i++) {
        let question = questions[i];
        table.push(
          <Fragment key={question.id}>
            <div className="xl:w-full border-b border-gray-300 py-5">
              <div className=" w-11/12 mx-auto xl:w-full xl:mx-0 ">
                <p className="text-lg text-indigo-800 font-bold">
                  {question.title}
                </p>
                <br />
                <div className="text-lg text-gray-800 question-desription">
                  {ReactHtmlParser(question.description)}
                </div>
                {question.images && question.images.data ? (
                  <img
                    alt={question.title}
                    src={question.images.data.full_url}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="pb-12">{this.showQuestionAnswers(question)}</div>
          </Fragment>
        );
      }
    }

    return table;
  };

  showQuizDetail = () => {
    // window.scrollTo(0, 0);
    let quiz = this.state.current_quiz;

    return (
      <Fragment>
        <div className="container mx-auto shadow bg-white rounded">
          <div className="xl:w-full border-b border-gray-300 py-5">
            <Affix offsetTop={this.props.affixTop}>
              <div
                className="flex justify-between items-center w-11/12 mx-auto"
                style={{ backgroundColor: "#fff", paddingTop: 10 }}
              >
                <p className="text-lg text-gray-800 font-bold">
                  {quiz.data.title}{" "}
                </p>

                <div className="">
                  {this.state.current_quiz.data.time_limit &&
                  this.state.current_quiz.data.time_limit > 0 ? (
                    <Countdown
                      renderer={timeRenderer}
                      date={
                        this.state.time_now +
                        this.state.current_quiz.data.time_limit * 60000
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>{" "}
            </Affix>
          </div>{" "}
          <div className="w-11/12 mx-auto">
            <div className="xl:w-9/12 mx-auto xl:mx-0">
              {quiz.data.mega_question ? (
                <React.Fragment>
                  <div className="xl:w-full border-b border-gray-300 py-5">
                    <div className=" w-11/12 mx-auto xl:w-full xl:mx-0 ">
                      <div className="text-lg text-gray-800 mega-question-description">
                        {ReactHtmlParser(quiz.data.mega_question)}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              {quiz.data.audio ? (
                <React.Fragment>
                  <div className="xl:w-full border-b border-gray-300 py-5">
                    <div className=" w-11/12 mx-auto xl:w-full xl:mx-0 ">
                      <ReactAudioPlayer
                        src={quiz.data.audio.data.full_url}
                        // autoPlay
                        controls
                      />
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              {quiz.questions && quiz.questions.length > 0
                ? this.showQuizQuestions(quiz.questions)
                : ""}
            </div>
          </div>
          <div
            className="w-full py-4 sm:px-12 px-4 bg-gray-100 mt-6
           flex justify-end rounded-bl rounded-br"
          >
            <button
              className="bg-indigo-700 transition duration-150 ease-in-out
               hover:bg-indigo-600 rounded text-white px-16 py-4 text-xl focus:outline-none"
              type="button"
              onClick={() => {
                this.computeResult();

                // this.setState({
                //   show_explanation: true,
                // });
              }}
            >
              Chấm điểm
            </button>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            userSelect: "none",
            webkitUserSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
          }}
        >
          <div
            className="w-full pt-12 bg-gray-200 pb-12"
            style={{ paddingTop: 10 }}
          >
            {this.state.page === "quiz" ? (
              <div className="container mx-auto px-6 pb-10 ">
                {this.showQuizDetail()}
              </div>
            ) : (
              <div
                className="container w-11/12 mx-auto py-12"
                style={{ height: 800 }}
              >
                <Loader style={{ margin: "auto" }} />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export class QuizHeadlessMulti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz_ids: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ quiz_ids: this.props.match.params.id.split(",") }, () => {
      log(this.state);
    });
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            userSelect: "none",
            webkitUserSelect: "none",
            WebkitUserSelect: "none",
            MozUserSelect: "none",
          }}
        >
          <div
            className="w-full pt-12 bg-gray-200 pb-12"
            style={{ paddingTop: 10 }}
          >
            {this.state.quiz_ids.length > 0 ? (
              <React.Fragment>
                <div className="container mx-auto px-6 pb-10 ">
                  {this.state.quiz_ids.map((quiz_id, idex) => {
                    return <QuizHeadless quizId={quiz_id} affixTop={10} />;
                  })}{" "}
                </div>
              </React.Fragment>
            ) : (
              <div
                className="container w-11/12 mx-auto py-12"
                style={{ height: 800 }}
              >
                <Loader style={{ margin: "auto" }} />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
