/* eslint-disable no-unused-vars */
import XXH from "xxhashjs";
import _ from "lodash";
import randomstring from "randomstring";
let xxsalt = 0xabcd;
const hostname = window && window.location && window.location.hostname;
const prefix = "[UB]";
const debug = hostname === "localhost" ? true : false;

let backendHost;
let UBAccountHost;

if (
  hostname === "quiz.ub.net" ||
  hostname.includes("ub.net") ||
  hostname.includes("thithu.vn")
) {
  backendHost = "https://quizapi.ub.net";
  UBAccountHost = "https://cms.ub.net";
} else {

  backendHost = "https://quizapi.ub.net";
  UBAccountHost = "https://cms.ub.net";


  // backendHost = "http://localhost:8000";
  // UBAccountHost = "http://localhost:18580";
}

let config = {
  debug: hostname === "localhost" ? true : false,
  api: {
    get_list_quiz: backendHost + "/quiz",
    account_user: UBAccountHost + "/api/account/user",
    account_login: UBAccountHost + "/api/account/login",
    session_verify: UBAccountHost + "/api/account/session_verify",
  },
  backend_host: backendHost,
  ub_account_host: UBAccountHost,
  app_hostname: hostname,
  root_path: "/#/",
};

export default config;
