/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
import logo from "assets/images/logo.png";
import logoWhite from "assets/images/logo-white.png";
const axios = require("axios");

export default class Footer extends Component {
  render() {
    return (
      <div
        className="background-color w-full lg:mt-60 px-4 lg:px-0 footer"
        style={{
          backgroundColor: "#334048",
          paddingBottom: 200,
        }}
      >
        <div className="mx-auto container w-11/12">
          <div
            className="lg:flex pt-8 lg:pt-16 border-b  pb-8"
            style={{
              borderColor: "rgba(229, 231, 235, 0.25)",
            }}
          >
            {/* <div className="ml-4 lg:ml-0 lg:w-1/4 pb-8 lg:pb-8 lg:pb-0">
              <img
                src={logoWhite}
                alt={"Logo"}
                width={"80%"}
                onClick={() => {
                  window.location.href = "/#/";
                }}
              />
            </div> */}
            <div className=" mt-2 lg:mt-0  lg:ml-0 lg:w-1/2">
              <div className="grid gap-8 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 lg:grid-cols-2 f-f-l text-white footer-links">
                <div>
                  <div className="text-xl font-bold pb-2 lg:pb-5 font-lato cursor-pointer">
                    Danh mục đề
                  </div>
                  <div className="text-base font-light font-lato">
                    <div className="pb-2 cursor-pointer">
                      {" "}
                      <a href={"/#/danh-muc/2/tieng-anh"}>Tiếng Anh</a>
                    </div>
                    <div className="pb-2 cursor-pointer">
                      <a href={"/#/danh-muc/1/tai-chinh-ngan-hang"}>
                        Tài Chính - Ngân Hàng
                      </a>
                    </div>
                    <div className="pb-2 cursor-pointer">
                      {" "}
                      <a href={"/#/danh-muc/5/thue"}>Thuế</a>
                    </div>
                    <div className="cursor-pointer">
                      {" "}
                      <a href={"/#/danh-muc/7/ke-toan-kiem-toan"}>
                        Kế toán - Kiểm toán
                      </a>
                    </div>
                    <div className="cursor-pointer">
                      {" "}
                      <a href={"/#/danh-muc/6/cong-chuc-vien-chuc"}>
                        Công chức - Viên chức
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-xl font-bold pb-2 lg:pb-5 font-lato cursor-pointer">
                    Tài khoản
                  </div>
                  <div className="text-base font-light font-lato">
                    <div className="pb-2 cursor-pointer">
                      {" "}
                      <a href={"/#/dang-ky"}>Đăng ký</a>
                    </div>
                    <div className="pb-2 cursor-pointer">
                      {" "}
                      <a href={"/#/dang-nhap"}>Đăng nhập</a>
                    </div>
                    {/* <div className="pb-2 cursor-pointer">Vase</div>
                    <div className="cursor-pointer">Plants</div> */}
                  </div>
                </div>
                {/* <div>
                  <div className="text-xl font-bold pb-2 lg:pb-5 font-lato cursor-pointer">
                    Công chức - Viên chức
                  </div>
                  <div className="text-base font-light font-lato">
                    <div className="pb-2 cursor-pointer">Chairs</div>
                    <div className="cursor-pointer">Tables</div>
                  </div>
                </div> */}
                {/* <div>
                  <div className="text-xl font-bold pb-2 lg:pb-5 font-lato cursor-pointer">
                    Exhibitions
                  </div>
                  <div className="text-base font-light font-lato">
                    <div className="pb-2 cursor-pointer">Chairs</div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="mx-auto w-11/12 pt-4 lg:pt-0 lg:w-1/4 pl-4 xl:pl-0">
              <div className="flex w-full">
                <div className="text-2xl font-bold font-lato uppercase text-white">
                  NEWSLETTER
                </div>
              </div>
              <div className="flex justify-center items-center rounded relative w-full mt-2 lg:mt-6">
                <input
                  type="email"
                  required
                  placeholder="Email Address"
                  id="email"
                  aria-label="email"
                  className="placeholder-white h-8 lg:h-12 pl-4 rounded-lg border border-white text-white bg-transparent focus:outline-none w-full text-base"
                />
                <button
                  type="submit"
                  className="flex items-center justify-center absolute right-0 inset-y-0 h-full focus:outline-none bg-white text-xs w-1/5 rounded px-2 sm:px-2 py-2 lg:py-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={26}
                    height={16}
                    viewBox="0 0 26 16"
                    fill="none"
                  >
                    <path
                      d="M0.785156 7.85693H24.7852"
                      stroke="#334048"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.9282 14.7141L24.7854 7.85693"
                      stroke="#334048"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.9282 1L24.7854 7.85714"
                      stroke="#334048"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div> */}
          </div>
          <div className="lg:ml-0 py-2 lg:py-8 w-full flex lg:flex-row flex-col-reverse justify-between items-center font-lato text-white text-base">
            <div className="lg:my-0 my-8">
              <strong>
                Copyright @2020 Công ty cổ phần Đầu tư & Phát triển UB Việt Nam
              </strong>
              <br />
              <div className="text-sm">
                ĐKKD số 0106080414 do Sở KH&ĐT TP. Hà Nội cấp ngày 09/01/2013
                <br />
                GCN hoạt động đào tạo số 6407/CN-SGD&ĐT do Sở GDĐT Hà Nội cấp
                ngày 21/07/2015 Trụ sở: Số 273 Đội Cấn, Phường Ngọc Hà, Quận Ba
                Đình, TP. Hà Nội
                <br /> UB Việt Nam giữ bản quyền mọi nội dung trên UB.NET,
                nghiêm cấm sao chép, buôn bán tài liệu bản quyền dưới mọi hình
                thức.
              </div>
            </div>
            <div className="mt-4 lg:mt-0 flex items-center"></div>
          </div>
        </div>
      </div>
    );
  }
}
