/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable no-unused-vars */
/* eslint-disable no-extend-native */

import logo from "./logo.svg";
import "./assets/main.css";
import "./App.less";

import React, { Component, useState } from "react";
import PropTypes from "prop-types";

import {
  BrowserRouter,
  HashRouter,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import { css, jsx } from "@emotion/react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Home from "controllers/Home";
import Quiz, { QuizHeadlessMulti } from "controllers/Quiz";
import Category from "controllers/Category";
import Signup from "controllers/Signup";
import Signin from "controllers/Signin";
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

String.prototype.capitalize = function () {
  return this.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function (comparer) {
  for (var i = 0; i < this.length; i++) {
    if (comparer(this[i])) return true;
  }
  return false;
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.pushIfNotExist = function (element, comparer) {
  if (!this.inArray(comparer)) {
    this.push(element);
  }
};

// adds an element to the array if it does not already exist using a comparer
// function
Array.prototype.removeIfExists = function (element) {
  const index = this.indexOf(element);
  if (index > -1) {
    this.splice(index, 1);
  }
};

function FingerPrint(props) {
  const [fingerId, setFingerId] = useState("");

  // Initialize an agent at application startup.
  const fpPromise = FingerprintJS.load();

  (async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    console.log(visitorId);
    setFingerId(visitorId);
  })();

  return (
    <React.Fragment>
      <div style={{ padding: 20 }}>
        {" "}
        ID: <strong>{fingerId}</strong>
      </div>
    </React.Fragment>
  );
}
let router = (
  <HashRouter>
    <Switch>
      <PropsRoute
        exact
        key="fingerprint"
        path="/fingerprint"
        name="home"
        component={FingerPrint}
      />
      <PropsRoute exact key="home" path="/" name="home" component={Home} />
      <PropsRoute
        exact
        key="signup"
        path="/dang-ky"
        name="signup"
        component={Signup}
      />

      <PropsRoute
        exact
        key="signin"
        path="/dang-nhap"
        name="signin"
        component={Signin}
      />
      <PropsRoute
        exact
        key="category"
        path="/danh-muc/:id/:slug"
        name="category"
        component={Category}
      />

      <PropsRoute
        exact
        key="QuizHeadlessMulti"
        path="/quiz_headless_multi/:id/:slug"
        name="QuizHeadlessMulti"
        component={QuizHeadlessMulti}
      />
      <PropsRoute
        exact
        key="quiz"
        path="/quiz/:id/:slug"
        name="quiz"
        component={Quiz}
      />
    </Switch>
  </HashRouter>
);

const AppRender = () => <React.Fragment>{router}</React.Fragment>;

export default class App extends Component {
  componentDidMount() {
    if (
      [
        "https://quiz.ub.net",
        "https://thithu.vn",
        "https://beta.thithu.vn",
        "http://localhost:3000",
      ].includes(window.location.origin)
    ) {
      let reg = window.location.href.match(
        /(https|http):\/\/(thithu.vn|beta.thithu.vn|localhost:3000)\/((?!#).)/g
      );

      if (reg != null) {
        let newUrl = window.location.href.replace(
          window.location.origin,
          window.location.origin + "/#"
        );

        if (newUrl.endsWith("#/")) {
          newUrl = newUrl.slice(0, -2);
        }
        window.location.href = newUrl;
      }
    }
  }
  render() {
    return AppRender();
  }
}

PropsRoute.propTypes = {
  component: PropTypes.func,
};
