export function getRandomItem(arr) {
  // get random index value
  const randomIndex = Math.floor(Math.random() * arr.length);

  // get random item
  const item = arr[randomIndex];

  return item;
}

export function findCommonElements(arr1, arr2) {
  return arr1.some((item) => arr2.includes(item));
}
