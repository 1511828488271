/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */
/** @jsxRuntime classic */
/** @jsx jsx */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import { css, jsx } from "@emotion/react";
import _ from "lodash";
import config from "config.js";
import log from "common/log.js";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { verifySessionToken, loadAccountData } from "common/account.js";
import moment from "moment";
import Moment from "react-moment";
import Footer from "controllers/components/Footer";
import LayoutHeader from "controllers/components/Header";
import Loader from "controllers/components/Loader";
import { getRandomItem, findCommonElements } from "common/array";
import "moment-timezone";
import logo from "assets/images/logo.png";
import logoWhite from "assets/images/logo-white.png";
import bg1 from "assets/images/bg1.png";
import bg2 from "assets/images/bg2.png";
import bg3 from "assets/images/bg3.png";
import bg4 from "assets/images/bg4.png";
import bg5 from "assets/images/bg5.png";
const axios = require("axios");
const slugify = require("slugify");
let bg = getRandomItem([bg1, bg2, bg3, bg4, bg5]);
let covers = [
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_9.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_10.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_11.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_12.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_13.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_14.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_15.png",
  "https://tuk-cdn.s3.amazonaws.com/assets/templates/Education-Portal/ep_16.png",
];

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: loadAccountData(),
      session_verified: false,
      page: "listing",
      quiz: [],
      current_quiz: {},
      form_questions: {},
      categories: [
        {
          id: 1,
          title: "Tài Chính - Ngân Hàng",
        },
        {
          id: 2,
          title: "Tiếng Anh",
        },
        {
          id: 3,
          title: "Kinh tế Vĩ mô & Vi mô",
        },
        {
          id: 5,
          title: "Thuế",
        },
        {
          id: 6,
          title: "Công chức, Viên chức",
        },
        {
          id: 7,
          title: "Kế toán, Kiểm toán",
        },
        {
          id: 8,
          title: "Tin Học",
        },
      ],
    };

    moment.locale("vi");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadListQuiz();
    this.verifySession();
  }

  verifySession = () => {
    let $this = this;
    let session = loadAccountData();
    if (session.token) {
      verifySessionToken(session).then(function (result) {
        log(result);
        $this.setState({ session: result, session_verified: true });
      });
    }
  };

  loadListQuiz = () => {
    let $this = this;

    axios
      .get(config.api.get_list_quiz, {
        params: { limit: 500 },
      })
      .then(function (response) {
        $this.setState({ quiz: response.data.data }, () => {
          log($this.state);
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});
  };

  showListQuiz = () => {
    let table = [];
    if (this.state.quiz.length > 0) {
      // Outer loop to create parent
      for (let i = 0; i < this.state.quiz.length; i++) {
        let quiz = this.state.quiz[i];
        let quiz_id = quiz.id.toString();
        let quiz_slug = slugify(quiz.title, {
          locale: "vi",
          lower: true,
          strict: true,
        });
        table.push(
          <Fragment key={`quiz-${quiz_id}`}>
            <a href={`/#/quiz/${quiz_id}/${quiz_slug}`}>
              <div className="bg-white shadow border border-gray-200 rounded transition duration-150 ease-in-out hover:shadow-lg cursor-pointer">
                <div className="relative w-full h-40">
                  <img
                    className="rounded-t z-0 w-full h-full absolute inset-0 object-cover"
                    src={
                      quiz.cover_image == null
                        ? getRandomItem(covers)
                        : quiz.cover_image.data.full_url
                    }
                    alt="banner"
                  />
                </div>
                <div className="pt-5 w-full h-full">
                  <div className="px-5 items-center justify-between mb-4">
                    <p className="text-indigo-400 leading-3 tracking-normal text-sm font-normal">
                      {quiz.category === null
                        ? "Đề trắc nghiệm"
                        : quiz.category.title}
                    </p>
                  </div>
                  <h4 className="px-5 text-lg leading-5 font-bold tracking-normal text-gray-800 mb-2">
                    {quiz.title}
                  </h4>

                  {quiz.time_limit === null ? (
                    <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4 pb-4">
                      {" "}
                    </p>
                  ) : (
                    <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4">
                      Giới hạn thời gian: {quiz.time_limit} phút
                    </p>
                  )}

                  <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4">
                    <Moment
                      locale="vi"
                      date={quiz.created_on}
                      format={"DD/MM/yyyy"}
                    />
                  </p>
                </div>
              </div>
            </a>
          </Fragment>
        );
      }
    }

    return table;
  };

  showListQuizByCategory = (category, index) => {
    let table = [];
    let e = 0;
    if (this.state.quiz.length > 0) {
      // Outer loop to create parent
      for (let i = 0; i < this.state.quiz.length; i++) {
        if (e === 8) {
          break;
        }
        let quiz = this.state.quiz[i];
        if (quiz.category) {
          if (quiz.category.id === category.id) {
            let quiz_id = quiz.id.toString();
            let quiz_slug = slugify(quiz.title, {
              locale: "vi",
              lower: true,
              strict: true,
            });

            table.push(
              <Fragment key={`quiz-${quiz_id}`}>
                <a key={`quiz-${quiz_id}`} href={`/#/quiz/${quiz_id}/${quiz_slug}`}>
                  <div
                    className={`bg-white shadow border border-gray-200 rounded transition duration-150 ease-in-out hover:shadow-lg cursor-pointer`}
                  >
                    <div className="relative w-full h-40">
                      <img
                        className="rounded-t z-0 w-full h-full absolute inset-0 object-cover"
                        src={
                          quiz.cover_image == null
                            ? getRandomItem(covers)
                            : quiz.cover_image.data.full_url
                        }
                        alt="banner"
                      />
                    </div>
                    <div className="pt-5 w-full h-full">
                      <div className="px-5 items-center justify-between mb-4">
                        <p className="text-indigo-400 leading-3 tracking-normal text-sm font-normal">
                          {quiz.category === null
                            ? "Đề trắc nghiệm"
                            : quiz.category.title}
                        </p>
                      </div>
                      <h4 className="px-5 text-lg leading-5 font-bold tracking-normal text-gray-800 mb-2">
                        {quiz.title}
                      </h4>

                      {quiz.time_limit === null ? (
                        <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4 pb-4">
                          {" "}
                        </p>
                      ) : (
                        <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4">
                          Giới hạn thời gian: {quiz.time_limit} phút
                        </p>
                      )}

                      <p className="px-5 text-gray-600 text-sm font-normal tracking-normal leading-5 mb-4">
                        <Moment
                          locale="vi"
                          date={quiz.created_on}
                          format={"DD/MM/yyyy"}
                        />
                      </p>
                    </div>
                  </div>
                </a>
              </Fragment>
            );

            e++;
          }
        }
      }
    }

    return table;
  };

  showQuizCategory = (category, index) => {
    return (
      <Fragment key={`category-${index}`}>
        <div
          className={`bg-no-repeat bg-right-bottom ${
            index % 2 !== 0 ? " bg-gray-50 " : " bg-white "
          }`}
          style={{
            paddingTop: 50,
          }}
        >
          <div className="py-6 lg:py-8">
            <div className="container mx-auto md:w-11/12 flex flex-col md:flex-row items-start md:items-center justify-between">
              <div>
                <h4
                  className={`text-2xl font-bold leading-tight ${
                    index % 2 !== 0 ? " text-indigo-800" : " text-gray-800 "
                  } `}
                >
                  {category.title}
                </h4>
              </div>
            </div>
          </div>

          <div className="container w-11/12 mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-8">
            {this.showListQuizByCategory(category, index)}
          </div>
          <div
            className="container w-11/12 mx-auto"
            style={{ paddingBottom: 60, paddingTop: 20 }}
          >
            <a
              href={`/#/danh-muc/${category.id}/${slugify(category.title, {
                locale: "vi",
                lower: true,
                strict: true,
              })}`}
              className=" hover:bg-indigo-700 hover:text-white bg-white transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-6 py-2 text-xs"
              style={{ display: "block", width: 140 }}
            >
              Xem thêm{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-arrow-narrow-right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#9e9e9e"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ marginLeft: 6, float: "right", marginTop: -4 }}
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="15" y1="16" x2="19" y2="12" />
                <line x1="15" y1="8" x2="19" y2="12" />
              </svg>
            </a>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <LayoutHeader
            session={this.state.session}
            sessionVerified={this.state.session_verified}
          />

          <div
            className="bg-gray-100 py-4 md:py-10 bg-no-repeat bg-right-bottom "
            style={{
              backgroundImage: `url(${bg})`,
              paddingTop: 120,
            }}
          >
            <section className="mx-auto container py-12 lg:px-4 xl:px-0 md:w-11/12">
              <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row">
                <div className="flex flex-col lg:w-6/12 md:w-8/12 px-4 lg:px-0 justify-center">
                  <h1 className="text-5xl sm:text-2xl md:text-4xl lg:text-6xl font-extrabold leading-tight text-gray-800">
                    Thi thử bằng đề thật.
                  </h1>
                  <p className="text-lg text-gray-600 font-light leading-relaxed pt-8">
                    Thithu.vn là trang web học tập, luyện đề thi sở hữu kho tài
                    liệu, đề thi ngành Ngân hàng, đề thi TOEIC. <br />
                    <br />
                    Nội dung chủ yếu của trang web này là các tài liệu và đề thi
                    phục vụ cho các đợt thi tuyển vào Ngân hàng, Cơ quan nhà
                    nước, ôn luyện thi chứng chỉ. <br />
                    <br />
                    Bên cạnh việc tìm kiếm tài liệu, đề thi trắc nghiệm nhanh
                    thì mọi người đều có thể chia sẻ tài liệu hữu ích mà mình sở
                    hữu lên thithu.vn.
                  </p>
                  <div className="mt-12 flex flex-wrap">
                    <div className="mr-6 mt-5 sm:mt-0 md:mt-5 lg:mt-0">
                      {this.state.session.token ? (
                        ""
                      ) : this.state.session_verified === false ? (
                        <a
                          href={"/#/dang-ky"}
                          className="focus:outline-none bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-4 text-xl"
                        >
                          Đăng ký tài khoản
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="mt-5 sm:mt-0 md:mt-5 lg:mt-0">
                      <button className="focus:outline-none transition duration-150 ease-in-out hover:bg-gray-200 rounded border border-indigo-600 text-indigo-600 px-8 py-4 text-xl">
                        Live Demo
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="w-full  bg-gray-200 pb-12">
            {this.state.quiz.length > 0 && this.state.page === "listing" ? (
              this.state.categories.map((e, i) => {
                return this.showQuizCategory(e, i);
              })
            ) : (
              <div
                className="container w-11/12 mx-auto py-12"
                style={{ height: 800 }}
              >
                <Loader style={{ margin: "auto" }} />
              </div>
            )}
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}
